import { GetStaticProps } from 'next'
import { getV3HomepageContent } from '@/graphql/HomepageV3'
import { getHomePage2024PageContent } from '@/graphql/Homepage2024v2'
import { HomePageExperiment } from '@/components/pages/home/HomePageExperiment'

export const getStaticProps: GetStaticProps = async ({ preview }) => {
  const homepageV3 = await getV3HomepageContent(!!preview)
  const homepage2024 = await getHomePage2024PageContent(!!preview)

  if (homepageV3 && homepage2024) {
    return {
      props: {
        pageContentOriginal: homepageV3,
        pageContentNew: homepage2024,
      },
    }
  }

  throw new Error('No Homepage content found')
}

export default HomePageExperiment
