import { HomePageRecord } from 'types'
import { useEffect, useState } from 'react'
import { HomePage } from '@/components/pages/home/HomePage'
import { Homepage2024 } from '@/components/pages/homepage2024/Homepage2024'
import { useKameleoonApi } from '@/lib/kamaleoon/useKameleoonApi'
import { HomepageLoadingSkeleton } from '@/components/pages/homepage2024/content/HomepageLoadingSkeleton'
import { useExperimentContext } from '@/context/ExperimentContext'
import * as Sentry from '@sentry/nextjs'

type HomePageExperimentProps = {
  pageContentOriginal: HomePageRecord
  pageContentNew: any
}

const EXPERIMENT_NAME = 'HOME-EXP5 New homepage v5'

export const HomePageExperiment: React.FC<HomePageExperimentProps> = ({ pageContentNew, pageContentOriginal }) => {
  const { setVariant } = useExperimentContext()
  const { waitForAssociatedVariation } = useKameleoonApi()
  const [selectedVariant, setSelectedVariant] = useState<string | null>(null)

  useEffect(() => {
    const getVariant = async () => {
      try {
        const variant = await waitForAssociatedVariation(EXPERIMENT_NAME)

        if (variant) {
          setSelectedVariant(variant.name)
          setVariant(variant.name)
        } else {
          setSelectedVariant('control')
          setVariant('control')
        }
      } catch (error) {
        Sentry.captureException('Error fetching Kamaleoon experiment:', error)
      }
    }

    getVariant()
  }, [])

  if (!selectedVariant) {
    return <HomepageLoadingSkeleton />
  }

  if (selectedVariant === 'New homepage') {
    return <Homepage2024 page={pageContentNew} />
  } else {
    return <HomePage pageContent={pageContentOriginal} />
  }
}
